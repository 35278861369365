import "./css/common.css";
import "./css/footer.css";
import "./css/nav.css";
import "./css/team.css";
import "./css/welcome.css";
import "./css/overview.css";
import "./css/contact.css";
import "./css/access.css";
import "./css/responsive.css";
import "./vendor/hint.css";

const MOBILE_HACK = 500;
let progress = 0;
let phase = 0;
let valuePropContainer;
let findOut = false;

let isMobile = window.innerWidth <= 900; //kludge to account for mobile browsers that hide/show their chrome on scroll

function router() {
  const canvas = document.querySelector("#video-canvas");
  const ctx = canvas.getContext("2d");
  const video = document.querySelector("video");

  setTimeout(function () {
    video.pause();
    video.ocurrentTime = 0;
    video.play();
  }, 300);

  video.addEventListener("play", () => {
    function step() {
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      requestAnimationFrame(step);
    }
    requestAnimationFrame(step);
  });
  // const previewWidth = document.querySelector(".preview-omnibar").offsetWidth;
  // const previewHeight = document.querySelector(".preview-omnibar").offsetHeight;

  // console.log(previewWidth, previewHeight);

  // document.querySelector(".preview-omnibar-text").style.marginBottom =
  // previewHeight / 2 + previewHeight / 15 + "px";

  // document.querySelector(".preview-omnibar-text").style.marginLeft =
  // previewWidth / 2 + "px";

  // let typed = new Typed(".preview-omnibar-text", {
  // loop: true,
  // showCursor: false,
  // strings: [
  // "Start a vacation planning project for a trip to Marfa",
  // "Compare my Jira and GitHub issues and prioritize my todo list",
  // "Show me a timeline of all my research on dog breeds",
  // "How much time is explicitly spent on supporting my OKRs",
  // "Create a folder that organizes all my wedding photos",
  // "Add highlights to Workday for the quarter review",
  // "How do I use the genetic engineering kit at https://the-odin.com",
  // "Review my last commits and let me know if this fixes the packet loss",
  // ],
  // typeSpeed: 100,
  // });

  // switch (window.location.pathname) {
  // case "/":
  // // window.location = "/";
  // break;

  // default:
  // document.body.style.overflowY = "scroll";

  // document.querySelectorAll(".active").forEach(function (it) {
  // it.classList.remove("active");
  // });

  // // e.target.classList.add("active");

  // document.querySelectorAll(".page").forEach(function (page) {
  // page.classList.add("hide");
  // });

  // let c = document.querySelector(p);

  // if (c) {
  // c.classList.remove("hide");
  // }

  // document.body.scrollTop = 0;
  // }

  function toggleFullscreen() {
    if (!document.webkitFullscreenElement) {
      if (video.requestFullScreen) {
        player.requestFullScreen();
      } else if (video.webkitRequestFullScreen) {
        player.webkitRequestFullScreen();
      } else if (video.mozRequestFullScreen) {
        player.mozRequestFullScreen();
      }
    } else {
      document.webkitExitFullscreen();
    }
  }

  var videos = document.querySelectorAll(".overview-section");

  videos.forEach(
    function (video) {
      var v = video.querySelector("video");

      video.addEventListener("dblclick", toggleFullscreen);

      video.addEventListener(
        "mouseover",
        function () {
          v.play();
        }.bind(this)
      );

      video.addEventListener(
        "mouseleave",
        function () {
          v.pause();
          v.currentTime = 0;
        }.bind(this)
      );
    }.bind(this)
  );

  let isOpen = false;
  window.addEventListener("click", function (e) {
    if (isOpen) {
      if (
        !document.querySelector(".nav-container").contains(e.target) &&
        !document.getElementById("kaba-nav-items").contains(e.target)
      ) {
        document.getElementById("button-home").classList.add("hide");
        document.querySelector(".nav-container").classList.remove("open");
        isOpen = false;
        document.body.style.overflowY = "scroll";
        return false;
      }
    } else {
      document.getElementById("button-home").classList.add("hide");
      document.querySelector(".nav-container").classList.remove("open");
      isOpen = false;
      return false;
    }
  });

  document.querySelector(".home").addEventListener("click", function (e) {
    // e.preventDefault();
    // e.stopPropagation();

    if (window.innerWidth <= 500) {
      if (isOpen) {
        document.getElementById("button-home").classList.add("hide");
        document.querySelector(".nav-container").classList.remove("open");
        isOpen = false;
        document.body.style.overflowY = "scroll";
        return false;
      }

      document.getElementById("button-home").classList.remove("hide");
      document.querySelector(".nav-container").classList.add("open");
      isOpen = true;
      document.body.style.overflowY = "none";

      return false;
    } else {
      window.location = "/";
    }
  });

  let pHidden = true;
  module.exports = { pHidden };
  document
    .getElementById("no-more-secrets")
    .addEventListener("click", function (e) {
      document.querySelector(".pane").style.display = pHidden
        ? "block"
        : "none";
      document.querySelector("nav").style.display = !pHidden ? "flex" : "none";
      document.querySelector("#welcome").style.display = !pHidden
        ? "block"
        : "none";
      document.querySelector("#overview").style.display = !pHidden
        ? "block"
        : "none";
      document.querySelector("#who-we-are").style.display = !pHidden
        ? "flex"
        : "none";
      document.querySelector("#contact").style.display = !pHidden
        ? "grid"
        : "none";
      document.querySelector("#request").style.display = !pHidden
        ? "grid"
        : "none";
      document.querySelector("footer").style.display = !pHidden
        ? "flex"
        : "none";
      pHidden = !pHidden;
    });

  document.querySelectorAll(".data-link").forEach(function (b) {
    b.addEventListener("click", function (e, item) {
      // e.preventDefault();
      // e.stopPropagation();

      document.querySelector(".nav-container").classList.remove("open");

      const p = e.target.dataset.href;

      // if (p === "#/" || p === "/") {
      // window.location = "/";
      // return;
      // }

      window.history.pushState(p, "", p);

      if (p === "/" || p === "#/") {
        window.location = "/";
        return;
      } else if (p.match(/^http/)) {
        return;
      } else {
        document.getElementById("button-home").classList.add("hide");
        document.querySelector(".nav-container").classList.remove("open");
        isOpen = false;
        document.body.style.overflowY = "scroll";

        document.querySelectorAll(".active").forEach(function (it) {
          it.classList.remove("active");
        });

        e.target.classList.add("active");

        document.querySelectorAll(".page").forEach(function (page) {
          page.classList.add("hide");
        });

        document.querySelector(p).classList.remove("hide");

        document.body.scrollTop = 0;
      }
    });
  });
}

window.addEventListener("load", () => {
  let realCheck = function (e) {
    const real = e.querySelector(".real-input input");
    return real && real.value !== "" ? false : true;
  };
  valuePropContainer = document.querySelector("#s-value-props");
  document.querySelector("#s-value-prop-1").style.display = "flex";
  document.querySelector("#s-value-prop-2").style.display = "none";
  document.querySelector("#s-value-prop-3").style.display = "none";
  gsap.to(document.querySelector(".hippo-loading"), {
    scale: 0.85,
    opacity: 0.5,
    duration: 1,
    ease: "bounce.out",
    repeat: -1,
    yoyo: true,
  });

  // let sendSubmit = function (params) {
  // let name = params.querySelector("#kaba-request-name");
  // let email =
  // params.querySelector("#kaba-request-email") ||
  // params.querySelector("#kaba-footer-form-email");
  // let more = params.querySelector("#kaba-request-body");

  // let body = {
  // subscription: {
  // name: name ? name.value : "",
  // email: email ? email.value : "",
  // body: more ? more.value : "",
  // },
  // };

  // fetch("https://api.kaba.ai/subscriptions", {
  // method: "POST",
  // body: JSON.stringify(body),
  // headers: {
  // "Content-type": "application/json; charset=UTF-8",
  // },
  // });

  // document.body.style.overflow = "hidden";
  // };

  // const form = document.getElementById("kaba-request-access-form");
  // form.addEventListener("submit", function (e) {
  // e.preventDefault();

  // let f = document.getElementById("request-access-form");
  // let done = document.getElementById("request-access-done");

  // done.classList.remove("hide");
  // f.classList.add("hide");
  // done.classList.add("animated", "bounceIn");
  // done.style.setProperty("--animate-duration", "1s");
  // // form.submit();
  // //
  // if (realCheck(f)) {
  // sendSubmit(form);
  // }
  // });

  // const footerForm = document.querySelector("#kaba-footer-form");
  // footerForm.addEventListener("submit", function (e) {
  // e.preventDefault();
  // e.stopPropagation();

  // let f = document.querySelector(".signup");
  // let done = document.querySelector(".sign-up-confirm");

  // done.style.display = "flex";
  // f.style.display = "none";
  // done.classList.add("animated", "bounceIn");
  // done.style.setProperty("--animate-duration", "1s");

  // if (realCheck(f)) {
  // sendSubmit(footerForm);
  // }

  // return false;
  // });
  // document
  // .getElementById("footer-submit-form")
  // .addEventListener("click", function (e) {
  // e.preventDefault();
  // footerForm.submit();
  // return false;
  // });

  // invoke the router
  router();

  document.body.addEventListener(
    "scroll",
    (e) => {
      const t = e.target.scrollTop;
      const st = Math.min(
        Math.max(0, e.target.scrollTop),
        e.target.scrollHeight
      );
      phase = Math.floor(st / window.innerHeight);
      progress = (st / window.innerHeight) % 1;
      handleScroll();
    },
    false
  );

  let resizeTimeout;
  let reloadPageTimeout = function () {
    // updateUI();
    window.location = "/";
  };

  window.addEventListener("resize", () => {
    isMobile = window.innerWidth <= 900;

    if (isMobile) return;

    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(reloadPageTimeout, 100);
  });

  document.querySelector("#btn-find-out").addEventListener("click", () => {
    findOut = true;
    const dur = 0.85;
    document.querySelector("#s-one").style.position = "fixed";

    gsap.to(document.querySelector("#s-one"), {
      y: "-100vh",
      duration: dur * 0.8,
    });
    gsap.to(document.querySelector("#s-two"), {
      opacity: 1,
      duration: dur,
      delay: dur * 0.2,
    });
    gsap.to(document.querySelector("#s-two"), {
      scale: 1,
      duration: dur * 0.8,
      delay: dur * 0.2,
    });
  });
});

const handleScroll = () => {
  // console.log(`phase-${phase}, progress-${progress.toFixed(1)}`);
  updateUI();
};

const updateUI = () => {
  if (findOut) {
    document.querySelector("#s-two").style.opacity =
      phase > 0 || (phase == 0 && progress > 0.8)
        ? 0
        : progress < 0.5
          ? 1
          : 1 - remap(progress, 0.5, 0.8, 0, 1);
  } else {
    if (phase == 0) {
      document.querySelector("#s-two").style.opacity = progress;
      document.querySelector("#s-two").style.transform =
        `scale(${remap(progress, 0, 1, 0.25, 1)})`;
    } else if (phase == 1 && progress < 0.8) {
      document.querySelector("#s-two").style.opacity =
        1 - remap(progress, 0, 0.8, 0, 1);
    } else {
      document.querySelector("#s-two").style.opacity = 0;
    }
  }

  if (document.querySelector("#s-two").style.opacity === "0") {
    document.querySelector("#s-two").style.display = "none";
  } else {
    document.querySelector("#s-two").style.display = "flex";
  }

  const modPhase = phase - (findOut ? 0 : 1);
  valuePropContainer.style.position = modPhase > 0 ? "fixed" : "relative";
  if (modPhase == 0) {
    valuePropContainer.style.opacity =
      progress > 0.5 ? remap(progress, 0.5, 1, 0, 1) : 0;
    document.querySelector("#s-value-prop-1").style.display = "flex";
    document.querySelector("#s-value-prop-2").style.display = "none";
    document.querySelector("#s-value-prop-3").style.display = "none";
  } else if (modPhase <= 3) {
    valuePropContainer.style.opacity = 1;
    if (modPhase == 1) {
      document.querySelector("#s-value-prop-1").style.display = "flex";
      document.querySelector("#s-value-prop-2").style.display = "none";
      document.querySelector("#s-value-prop-3").style.display = "none";
    } else if (modPhase == 2) {
      document.querySelector("#s-value-prop-1").style.display = "none";
      document.querySelector("#s-value-prop-2").style.display = "flex";
      document.querySelector("#s-value-prop-3").style.display = "none";
    } else if (modPhase == 3) {
      document.querySelector("#s-value-prop-1").style.display = "none";
      document.querySelector("#s-value-prop-2").style.display = "none";
      document.querySelector("#s-value-prop-3").style.display = "flex";
    }
  } else if (modPhase == 4) {
    valuePropContainer.style.opacity = 1 - progress * 2;
  } else {
    valuePropContainer.style.opacity = 0;
    document.querySelector("#s-value-prop-1").style.display = "none";
    document.querySelector("#s-value-prop-2").style.display = "none";
    document.querySelector("#s-value-prop-3").style.display = "none";
  }
};

const getPixelDensityCorrectedContext = (canvas) => {
  let pixelRatio = window.devicePixelRatio;
  let sizeOnScreen = canvas.getBoundingClientRect();
  canvas.width = sizeOnScreen.width * pixelRatio;
  canvas.height = sizeOnScreen.height * pixelRatio;
  canvas.style.width = canvas.width / pixelRatio + "px";
  canvas.style.height = canvas.height / pixelRatio + "px";
  let context = canvas.getContext("2d");
  context.scale(pixelRatio, pixelRatio);

  return context;
};

function remap(number, inMin, inMax, outMin, outMax) {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}
